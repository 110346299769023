@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 99%;
    --foreground: 0 0% 10%;
    --card: 0 0% 99%;
    --card-foreground: 0 0% 8%;
    --popover: 0 0% 99%;
    --popover-foreground: 0 0% 8%;
    --primary: 335 76% 39%;
    --primary-foreground: 0 0% 99%;
    --secondary: 330 59% 27%;
    --secondary-foreground: 330 15% 87%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 357 83% 37%;
    --destructive-foreground: 354 16% 87%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 335 76% 39%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 10%;
    --foreground: 0 0% 90%;
    --card: 0 0% 10%;
    --card-foreground: 0 0% 90%;
    --popover: 0 0% 10%;
    --popover-foreground: 0 0% 90%;
    --primary: 335 76% 39%;
    --primary-foreground: 336 12% 92%;
    --secondary: 330 59% 27%;
    --secondary-foreground: 330 15% 87%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 3 93% 63%;
    --destructive-foreground: 354 16% 87%;
    --border: 240 3.7% 15.9%;
    --input: 0 4.65% 16.86%;
    --ring: 335 76% 39%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE Edge and Firefox */
  .scrollbar-none {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
